import React, { useEffect } from "react"
import { withPrefix } from "gatsby-link"
import Metatags from "../components/metatags"

const RedirectIndex = () => {
  useEffect(() => {
    const availableLanguages = ["en", "es", "pt"]
    let langKey = ""
    // search for browser languages
    if (typeof window !== "undefined") {
      for (const element of window.navigator.languages) {
        if (availableLanguages.includes(element.substring(0, 2))) {
          langKey = element.substring(0, 2)
          break
        }
      }

      // defaults to English
      if (langKey === "") langKey = "pt-br"
      if (langKey === "pt") langKey = "pt-br"
      const homeUrl = withPrefix(`/${langKey}/`)
      window.location.replace(homeUrl)
    }
  }, [])

  return (
    <Metatags
      title="Multilogica"
      description=""
      url="https://newsite.multilogica.ind.br"
      image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
      keywords=""
      language="en"
    />
  )
}

export default RedirectIndex
